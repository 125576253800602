<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group label="시도" label-for="sido">
          <v-select
            id="sido"
            v-model="sido"
            label="sidoNm"
            class="select-size-sm"
            placeholder="선택"
            :options="sidoList"
            @input="sidoChange($event)"
          />
        </b-form-group>
      </b-col>

      <b-col md="8">
        <b-form-group label="시군구" label-for="sigungu">
          <v-select
            id="sigungu"
            v-model="mainSelected.SIGUNGU"
            class="sigungu-style select-size-sm"
            multiple
            label="sigunguNm"
            placeholder="선택"
            :options="sigunguList"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-form-group>
          <b-form-input
            id="item-name"
            v-model="searchItem"
            type="text"
            class="select-size-sm"
            :placeholder="inputPlaceholder"
            @keyup.enter="searchItemProc"
          />
        </b-form-group>
      </b-col>
      <!-- Search Button -->
      <b-col md="3" style="padding: 0; ">
        <b-button size="sm" variant="relief-primary" style="width: 80px;" @click="searchItemProc">
          검색
        </b-button>
      </b-col>
      <!--      <b-col md="2" style="padding: 0;">
        <b-button size="sm" variant="relief-primary" @click="closeItemProc">
          닫기
        </b-button>
      </b-col>-->
    </b-row>
    <b-row
      v-show="isShow"
      style="border: solid #cac9d0 1px;background: whitesmoke;padding: 10px;border-radius: 10px;margin-top: 5px;width: 300px;"
    >
      <b-col>
        <table class="scrollTable ">
          <tr>
            <td colspan="2">
              <BButton size="sm" class="mb-1" style="width: 100%;" @click="closeItemProc">
                닫기
              </BButton>
            </td>
          </tr>
          <tr>
            <th style="width:205px">
              <h5><b>건물 전체</b></h5>
            </th>
            <th style="width:30px">
              <input v-model="selectAll" type="checkbox" @click="buildAllClick" />
            </th>
          </tr>
          <tr>
            <td v-show="loading" class="text-center pt-1">
              <b-spinner label="Loading..." />
            </td>
          </tr>
          <tr v-for="(item, index) in rows" :key="index">
            <td>
              {{ item.bplcnm }}
            </td>
            <td>
              <!--              <input
                :id="item.bldPk"
                v-model="buildChecked"
                :value="item"
                type="checkbox"
                @click="checkItem(item)"
              >-->

              <input
                :id="item.bldPk"
                v-model="buildChecked"
                :checked="checked"
                :value="item"
                type="checkbox"
                @change="
                  () => {
                    buildPoints([item.bldPk, item.stX, item.stY]);
                  }
                "
              />
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BSpinner,
  },
  props: {
    inputPlaceholder: {
      type: String,
      required: false,
      default: '동단위로 검색해주세요',
    },
  },

  data() {
    return {
      loading: true,
      searchItem: '',
      columns: [
        {
          label: '건물',
          field: 'bldNm',
        },
      ],
      rows: [],
      isShow: false,
      searchBtn: '검색',

      /*  체크박스  */
      selected: [],
      selectAll: false,
      buildChecked: [],
      checked: '',
      /*  체크박스  */

      sido: [],
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      sidoList: 'main/getSidoList',
      sigunguList: 'main/getSigunguList',
    }),
  },
  created() {
    // 공통코드 불러오기
    this.$store.dispatch('main/FETCH_COMM_CODE_DATA');

    // 시도 정보 불러오기
    this.$store.dispatch('main/FETCH_SIDO_LIST');
  },
  mounted() {},
  methods: {
    searchItemProc() {
      // 상호명 검색
      if (this.searchItem) {
        // 검색어가 있고 리스트 펼처진 상태
        this.isShow = true;
        this.loading = true;
        // if (this.searchBtn === '검색') {
        //   this.searchBtn = '닫기';
        // } else {
        //   this.searchBtn = '검색';

        // 닫기 버튼 클릭시 초기화
        // this.selectAll = false;
        this.rows = [];
        this.buildChecked = [];

        this.rows.forEach(() => {
          this.buildChecked = [];
          this.buildPoints();
        });
        // }
        // 시도 셀렉트박스 설정시, 배열이 JSON 변경되는 버그, 강제로 변환
        this.$store.state.main.selected.SIDO.push(this.sido);

        this.$store
          .dispatch('main/FETCH_SEARCH_AUTH_DATA', {
            dataCode: 'searchAuthData', // 인허가 데이터 조회 ex)스타벅스
            searchItem: this.searchItem,
            mainSelected: this.mainSelected,
            base_bld_layer: $('#buldLayers').val(), // 검색조건추가(배경건물명)
          })
          .then(() => {
            this.rows = this.$store.state.main.searchItem;
            this.loading = !this.loading;
          })
          .catch(() => {
            console.error('오류');
          });
      } else {
        alert('내용을 입력해 주세요.');
      }
    },
    buildPoints() {
      const crds = this.buildChecked.map(({ bplcnm, ...rest }) => rest); // 특정값 제거
      // eslint-disable-next-line no-undef
      gis.search.moveToMultiBldCrd(map, crds);
    },
    closeItemProc() {
      this.isShow = false;
      this.loading = false;
      this.searchItem = '';
      this.selectAll = false;

      this.rows = [];
      this.buildChecked = [];
      // this.rows.forEach(() => {
      //   this.buildChecked = [];
      //   this.buildPoints();
      // });
    },

    /* checkItem(item) { // 체크박스 클릭시
      // console.log(' aa > ', this.buildChecked);
      this.checkedList.push(item);
      const checkedList = this.checkedList;
      const point = [];
      checkedList.forEach((element) => {
        const temp = {};
        temp.bldPk = element.bldPk;
        temp.x = element.x;
        temp.y = element.y;
        point.push(temp);
      });
      gis.search.moveToMultiBldCrd(map, point);
    } */
    buildAllClick() {
      this.selected = [];
      if (!this.selectAll) {
        this.rows.forEach((item) => {
          const temp = {};
          temp.bldPk = item.bldPk;
          temp.bplcnm = item.bplcnm;
          temp.x = item.x;
          temp.y = item.y;
          this.buildChecked.push(temp);
          this.buildPoints();
        });
      } else {
        this.selectAll = false;
        this.rows.forEach(() => {
          this.buildChecked = [];
          this.buildPoints();
        });
      }
    },
    // 시도 변경시 시군구 데이터 조회
    sidoChange(event) {
      // console.log(this.sido);
      if (event.length !== 0) {
        // 선택된 값이 있는 경우 sigungu 데이터 조회
        this.$store.dispatch('main/FETCH_SIGUNGU_LIST', this.sido.sidoId);
      } else {
        // 선택된 값이 없는 경우 선택된 sigungu 초기화
        this.$store.dispatch('main/FETCH_SIGUNGU_LIST', null);
      }
    },
  },
};
</script>

<style>
#sigungu .vs__selected-options {
  flex-wrap: unset !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
</style>
<style scoped lang="scss">
.scrollTable {
  display: block;
  overflow: auto;
}

table {
  width: 265px;
  height: 400px;
}

input[type='checkbox'] {
  display: block !important;
}
</style>
